import React from 'react';
import Footer from '../../global/footer';
import Header from '../../global/header';
import Body from './body';

const Home = () => {

    return(
        <div>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    )
}

export default Home;