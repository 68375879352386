import React from 'react';
import '../../../styles/Header.scss'
import { useNavigate } from "react-router-dom";

const Header = () => {
   const navigate  = useNavigate()

    return (
        <div className={'headerContainer'}>
            <div>
                <img onClick={() => navigate("/")} className={'coterieLogo'} src="/clearnoticeslogo.png" alt="CoterieLogo" />
            </div>
        </div>
    )
}

export default Header;