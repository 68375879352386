import React from 'react';
import '../../../styles/Footer.scss'
import { useNavigate } from "react-router-dom";

const Footer = () => {
   const navigate  = useNavigate()

    return (
        <div onClick={() => navigate("/")} className={'footerContainer'}>
            <div className='alignText'>
                <img className={'footerLogo'} src="/clearnoticeslogo.png" alt="CoterieLogo" />
                <p className='copyWrite'>© 2022 Clear Notices</p>
            </div>
        </div>
    )
}

export default Footer;