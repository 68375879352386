import React, { useState } from 'react';
import '../../../../styles/Home.scss';
import '../../../../styles/Forms.scss';
import SimpleModal from '../../../global/modal';

const BodyMain = () => {
    const [openModal, setOpenModal] = useState(false)

    return (
        <div className={'quoteContainer'}>
            <div className={'headingContainer'}>
                <div className={'imageContainer'}>
                    <img className={'viewPeople'} src="/viewNotice.jpg" alt="people looking at computer" />
                </div>
                <div className={'homeHeaderContainer'}>
                    <div className='headerContent'>
                        <h1 className='home-header'>Keeping Insurance Compliant</h1>
                        <span className='home-header-text'>The first tech-enabled platform to allow compliant insurance cancellations, renewals, and customer notices.</span>
                        <br/>
                        <button data-testid={'get-a-policy-btn'} className={'homeButton'} onClick={() => setOpenModal(true)}>
                           Join Waiting List
                        </button>
                    </div>
                </div>
            </div>
            <br/>
            <hr/>
            <div className='compliance-container'>
                <div className='compliance-text-container'>
                    <div className='content-center-positioned'>
                        <p className='compliance-header'> State level compliance for:</p>
                        <ul className={'noMargin'}>
                            <div className="complianceTypes"><div><img className={'checkIcon'} src="/check.png" alt="check icon" /></div><div>Cancel notice lead time</div></div>
                            <div className="complianceTypes"><div><img className={'checkIcon'} src="/check.png" alt="check icon" /></div><div>Acceptable cancellation reasons and verbiage</div></div>
                            <div className="complianceTypes"><div><img className={'checkIcon'} src="/check.png" alt="check icon" /></div><div>Premium increase notifications</div></div>
                            <div className="complianceTypes"><div><img className={'checkIcon'} src="/check.png" alt="check icon" /></div><div>Cancel/rewrite or intracompany transfer rules</div></div>
                        </ul>
                    </div>
                </div>
                <div className='shield-container'>
                    <img className={'shieldIcon'} src="/shield.png" alt="check icon" />
                </div>
            </div>
            <hr/>
            <div className='waitingListContainer'>
                <p className='no-margin'>Want to save time managing your policies? Click the button below to join our waiting list and learn more.</p>
                <button data-testid={'get-a-policy-btn'} className={'homeButton'} onClick={() => setOpenModal(true)}>
                    Learn More
                </button>
            </div>
            <SimpleModal openModal={openModal} setOpenModal={setOpenModal}/>
        </div>
    )
}

export default BodyMain;