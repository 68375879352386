import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const ChimpForm = ({status, message, onValidated, setOpenModal}) => {

    const [email, setEmail] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });

    }

    useEffect(() => {
        if(status === 'success') {
            setOpenModal(false)
            toast.success( '😊 Success, you are subscribed', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
    }, [status, setOpenModal])

    return(
        <form
        className="mc__form"
        onSubmit={(e) => handleSubmit(e)}
        >
            <h3 className="mc__title">
                {status === "success" ? "Success!" :
                    "Join Our Waiting List 😊"}
            </h3>

            {status === "sending" && (
                <div
                    className="mc__alert mc__alert--sending"
                >sending...</div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            {status !== "success" ? (
                <div className="mc__field-container">

                    <input
                        className='form-input'
                        label="Email"
                        onChange={(e) =>setEmail(e.target.value)}
                        type="email"
                        value={email}
                        placeholder="your@email.com"
                    />
                </div>
            ) : null}

            {
                status === 'success' ? <button
                    onClick={() => setOpenModal(false)}
                    className='formButton'
                    label="close"
                    value='close'
                >Close</button> : <input
                    className='formButton'
                    label="subscribe"
                    type="submit"
                    disabled={ email === "" && true}
                    values={[email]}
                />
            }
    </form>
    )
}

export default ChimpForm;