import React from 'react';
import BodyMain from './bodyMain';
import '../../../../styles/Home.scss';

const Body = () => {

    return (
        <div className={'container'}>
             <BodyMain/>
        </div>
    )
}
export default Body;