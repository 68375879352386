import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRoutes from './routes/index.js';
import reportWebVitals from './reportWebVitals';
import reducers from './store/store.js'
import thunk from 'redux-thunk';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, compose, applyMiddleware, combineReducers } from "redux"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";

const rootReducer = combineReducers(
  reducers,
)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Clear Notices</title>
      </Helmet>
        <AppRoutes/>
      </BrowserRouter>
      <ToastContainer />
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
