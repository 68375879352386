import { createSlice } from '@reduxjs/toolkit'

export const formSlice = createSlice({
    name: 'form',
    initialState: {},
    reducers: {
    },
})

// Action creators are generated for each case reducer function

export default formSlice.reducer