import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import ChimpForm from '../chimpForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '40px',
  bgcolor: '#5072F2',
  border: '0px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
};

const SimpleModal = (props) => {
  const { openModal, setOpenModal } = props;
  const handleClose = () => setOpenModal(false);

  const postUrl = process.env.REACT_APP_MC;
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MailchimpSubscribe
            url={postUrl} 
            render={({ subscribe, status, message }) => (
                <ChimpForm
                    status={status} 
                    message={message}
                    onValidated={formData => subscribe(formData)}
                    setOpenModal={setOpenModal}
                />
            )}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default SimpleModal;